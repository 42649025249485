@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  body {
    @apply mx-5 pt-5;
    @apply sm:w-96 sm:m-auto;
    @apply bg-slate-900 text-white;
  }

  .header {
    @apply text-center cursor-pointer m-2;
  }

  .header-title {
    @apply mx-auto text-2xl ;
  }

  .subtitle-container {
    @apply hidden group-focus:block w-auto mx-auto;
  }
  
  .header-subtitle {
    @apply mx-auto px-4 whitespace-pre-line;
    @apply text-sm ;
  }

  .project {
    @apply transition-all;
    @apply block p-2 m-2 cursor-pointer;
    @apply border-slate-800	 border-2 rounded-lg;
    @apply hover:border-slate-600 hover:scale-105 hover:py-4;
  }

  .project-header {
    @apply block justify-between;
  }

  .project-title {
    @apply mr-3 inline-block;
  }

  .project-status {
    @apply mr-2 p-0.5 inline-block;
    @apply border-slate-500 border-2 rounded-lg;
  }

  .ongoing {
    @apply bg-indigo-900;

  }

  .complete {
    @apply bg-green-900;
  }

  .reveal-button {
    @apply bg-blue-800;
    @apply mb-1 mx-auto px-2 block;
    @apply border-2 rounded-lg border-slate-600;
  }

  .description-container {
    @apply block;
  }

  .project-description {
    @apply mb-3;
  }

  .project-link-list {
    @apply block;
  }

  .project-link {
    @apply mt-1 mr-1 p-1 inline-block;
    @apply bg-blue-800 border-slate-600 border-2 rounded-lg;

    @apply hover:bg-blue-700 hover:border-slate-500;
    @apply focus:bg-blue-600 focus:border-slate-400;
  }

  .skill-list {
    @apply inline-block my-2;
  }

  .skill {
    @apply mr-1 mb-1 p-1 inline-block;
    @apply bg-slate-800 border-slate-600 border-2 rounded-lg;
    
    @apply hover:bg-slate-600 hover:border-slate-500;
    @apply focus:bg-slate-500 focus:border-slate-400;
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
